import { RequestType } from '../../common/RequestTypes';
import { RestNetworkObject } from './../../common/RestNetworkObject';
import { AdvancedExpertUserUnavailableRequest } from './AdvancedExpertUserUnavailableRequest';

export class AdvancedExpertUserUnavailbleNetworkObject extends RestNetworkObject<AdvancedExpertUserUnavailableRequest> {
  constructor(params: AdvancedExpertUserUnavailableRequest) {
    super(
      params,
      '/generic/advanced-expert-user-unavailable',
      RequestType.POST
    );
  }
}
