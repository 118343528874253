import { SpNavigationItemType } from '@dev.smartpricing/sp-vue-components'
import { TrackingFields } from '~/constants/trackingFields'
import { TrackingMessages } from '~/constants/trackingMessages'

const trackClick = (page: string) => {
	utilTracking.track(TrackingMessages.PAGE_VIEW, { [TrackingFields.PAGE_NAME]: page })
}

export function getSmartPayingNavigationItems(): SpNavigationItemType[] {
	const items: SpNavigationItemType[] = []

	const { isMobile } = useMobileViewport()
	const router = useRouter()
	const { isSmartupsellingEnabled, isSmartpayingEnabled, isSmartpayingFreeEnabled, isSmartConnectCrmEnabled } =
		useCoreStore()
	const { isGhtDemoUser } = storeToRefs(useUserStore())
	const { hasFullIntegration, hasPartialIntegration } = useSmartpayingStore()

	if (
		!isGhtDemoUser.value &&
		isSmartConnectCrmEnabled &&
		!router.currentRoute.value.path.startsWith('/auto-onboarding')
	) {
		items.push({
			id: '$P_crm_marketing',
			type: 'expandable',
			text: useLocale().translate('CRM_MARKETING'),
			disableOnMobile: false,
			onClick: () => {},
			icon: SpGeneratedIcon.BasicMarketing,
			class: 'bg-main-blue-600',
			activeCondition: () =>
				['/crm/newsletter', '/crm/audience'].some((link) => useRouter().currentRoute.value.path.startsWith(link)),
			navigationItems: [
				{
					id: '$P_crm_newsletter',
					text: useLocale().translate('CRMNEWSLETTER_PAGE-TITLE_NEWSLETTER'),
					icon: SpCommonIcon.Mail,
					type: 'button',
					closeOnClick: isMobile.value,
					onClick: () => {
						location.assign(window.location.origin + '/crm/newsletter')
						trackClick('CrmNewsletter')
					},
					activeCondition: () => useRouter().currentRoute.value.path.startsWith('/crm/newsletter'),
				},
				{
					id: '$P_crm_audience',
					text: useLocale().translate('CRMAUDIENCE_PAGE-TITLE'),
					icon: SpGeneratedIcon.BasicAudience,
					type: 'button',
					closeOnClick: isMobile.value,
					onClick: () => {
						location.assign(window.location.origin + '/crm/audience')
						trackClick('CrmAudience')
					},
					activeCondition: () => useRouter().currentRoute.value.path.startsWith('/crm/audience'),
				},
				{
					id: '$P_crm_analytics',
					text: useLocale().translate('menu.crm.analytics.title'),
					icon: SpGeneratedIcon.BasicAnalytics,
					type: 'button',
					closeOnClick: isMobile.value,
					onClick: () => {
						location.assign(window.location.origin + '/crm/analytics')
						trackClick('CrmAnalytics')
					},
					activeCondition: () => useRouter().currentRoute.value.path.startsWith('/crm/analytics'),
				},
			],
		})
	}

	if (
		!isGhtDemoUser.value &&
		!router.currentRoute.value.path.startsWith('/auto-onboarding') &&
		isSmartpayingEnabled &&
		(hasFullIntegration || (hasPartialIntegration && isSmartupsellingEnabled))
	) {
		items.push({
			id: '$P_automations',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys.SMARTPAYING_AUTOMATIONS_AUTOMATIONS),
			icon: SpGeneratedIcon.BasicAutomation,
			onClick: () => {
				location.assign(window.location.origin + '/automations')
				trackClick('Automations')
			},
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/automations'),
		})
	}

	if (
		!isGhtDemoUser.value &&
		isSmartupsellingEnabled &&
		!router.currentRoute.value.path.startsWith('/auto-onboarding')
	) {
		items.push({
			id: 'marketplace',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys.SMARTPAYING_UPSELLING),
			icon: SpGeneratedIcon.BasicMarketplace,
			onClick: () => {
				location.assign(window.location.origin + '/marketplace')
				trackClick('Smartpaying marketplace')
			},
			disableOnMobile: true,
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/marketplace'),
		})
	}

	if (
		!isGhtDemoUser.value &&
		(isSmartpayingEnabled || isSmartpayingFreeEnabled) &&
		!router.currentRoute.value.path.startsWith('/auto-onboarding')
	) {
		items.push({
			id: 'smartpaying',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys.PAYMENTS),
			icon: SpGeneratedIcon.BasicCreditCard,
			onClick: () => {
				location.assign(window.location.origin + '/payments')
				trackClick('Smartpaying')
			},
			disableOnMobile: false,
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/payments'),
		})
	}

	if (
		!isGhtDemoUser.value &&
		isSmartConnectCrmEnabled &&
		!router.currentRoute.value.path.startsWith('/auto-onboarding')
	) {
		items.push({
			id: '$P_crm_customers',
			text: useLocale().translate('SMARTPAYING_CUSTOMERS'),
			icon: SpGeneratedIcon.BasicCustomers,
			type: 'button',
			closeOnClick: isMobile.value,
			onClick: () => {
				location.assign(window.location.origin + '/crm/customers')
				trackClick('CrmCustomers')
			},
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/crm/customers'),
		})

		items.push({
			id: '$P_crm_settings',
			text: useLocale().translate('menu.crm.settings'),
			icon: SpGeneratedIcon.BasicSetting,
			type: 'button',
			closeOnClick: isMobile.value,
			onClick: () => {
				location.assign(window.location.origin + '/crm/settings/mails')
				trackClick('CrmSettingsAccommodation')
			},
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/crm/settings'),
		})
	}

	return items
}
