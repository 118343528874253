<script setup lang="ts">
import { UserFeedback } from '../../../submodules/feedback/types/UserFeedback'
import { TrackingMessages } from '../../constants/trackingMessages'
import { TranslationKey } from '../../i18n/TranslationKeys'
import {
	FeedbackModalImprovement,
	FeedbackModalOptions,
	FeedbackModalPhase,
	FeedbackModalStatus,
} from '../../types/Feedback'

const props = defineProps<{
	modalOptions: FeedbackModalOptions
	modalStatus: FeedbackModalStatus
	onUpdateModalPhase: (phase: FeedbackModalPhase) => void
	onUpdateModalStatus: (status: FeedbackModalStatus) => void
	onExpandTextArea: (expanded: boolean) => void
	onModalClose: (feedbackToGive?: UserFeedback) => void
}>()

const i18n = useI18n()
const { locale, t } = i18n
const { isMobile } = useMobileViewport()
const currentFeedbackModalImprovementsSelected = ref<string[]>([])

const isTextAreaExpanded = ref(false)
const textAreaContent = ref('')

const currentFeedbackModalImprovements: ComputedRef<FeedbackModalImprovement[]> = computed(() => {
	const baseImprovementsList =
		props.modalStatus.chosenFeedback === Feedback.SUPER_HAPPY
			? [
					TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_1'],
					TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_2'],
					TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_3'],
					TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_4'],
				]
			: props.modalOptions.improvementsList

	const formattedImprovementsList = baseImprovementsList.map((improvementLabel) => {
		return {
			label: improvementLabel,
			isSelected: currentFeedbackModalImprovementsSelected.value.includes(improvementLabel),
		}
	})

	formattedImprovementsList.push({
		label: TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_OTHER'],
		isSelected: currentFeedbackModalImprovementsSelected.value.includes(
			TranslationKeys['FEEDBACK.MODAL.GENERIC_IMPROVEMENT_OTHER']
		),
	})

	return formattedImprovementsList
})

const getTextAreaRows = computed(() => {
	return isTextAreaExpanded.value ? 3 : 1
})

const onImprovementCheckboxClick = (improvement: FeedbackModalImprovement) => {
	if (currentFeedbackModalImprovementsSelected.value.includes(improvement.label)) {
		currentFeedbackModalImprovementsSelected.value = currentFeedbackModalImprovementsSelected.value.filter(
			(selectedImprovement) => selectedImprovement !== improvement.label
		)
	} else {
		currentFeedbackModalImprovementsSelected.value.push(improvement.label)
	}
}

const getImprovementCheckboxClass = (improvement: FeedbackModalImprovement) => {
	const checkboxClasses = ['flex', 'px-3', 'py-2', 'gap-2', 'items-center', 'rounded-md', 'text-secondary-gray-900']

	if (improvement.isSelected) {
		checkboxClasses.push('bg-main-blue-100')
	} else {
		checkboxClasses.push('bg-secondary-gray-50')
	}

	return cn(checkboxClasses.join(' '))
}

const getFeedbackIcon = computed(() => {
	switch (props.modalStatus.chosenFeedback) {
		case Feedback.SUPER_SAD:
			return '😖'
		case Feedback.SAD:
			return '😔'
		case Feedback.NEUTRAL:
			return '😑'
		case Feedback.HAPPY:
			return '🙂'
		case Feedback.SUPER_HAPPY:
			return '😍'
		default:
			return ''
	}
})

const feedbackMap = {
	[Feedback.SUPER_SAD]: 1,
	[Feedback.SAD]: 3,
	[Feedback.NEUTRAL]: 5,
	[Feedback.HAPPY]: 7,
	[Feedback.SUPER_HAPPY]: 10,
}

const expandTextArea = () => {
	isTextAreaExpanded.value = true

	props.onExpandTextArea(true)
}

const onPreviousPhaseClick = () => {
	const newFeedbackStatus: FeedbackModalStatus = {
		chosenFeedback: props.modalStatus.chosenFeedback,
		chosenImprovements: undefined,
		chosenImprovementsOther: undefined,
	}

	props.onUpdateModalStatus(newFeedbackStatus)
	props.onUpdateModalPhase(FeedbackModalPhase.Feedback)
}

const onFeedbackSend = () => {
	const newFeedbackStatus: FeedbackModalStatus = {
		chosenFeedback: props.modalStatus.chosenFeedback,
		chosenImprovements: currentFeedbackModalImprovementsSelected.value,
		chosenImprovementsOther: currentFeedbackModalImprovementsSelected.value.includes('Other')
			? textAreaContent.value
			: undefined,
	}

	// We set the current locale to 'en' in order to send the feedback
	// improvements list in english.
	const currentLocale = locale.value
	locale.value = 'en'

	useFeedbackStore().giveFeedback({
		eventId: useFeedbackStore().activeFeedback!.id,
		feedbackScore: feedbackMap[props.modalStatus.chosenFeedback!],
		feedbackComment: textAreaContent.value,
		feedbackImprovements: currentFeedbackModalImprovementsSelected.value.map((improvement) =>
			i18n.t(TranslationKeys[improvement as TranslationKey])
		),
		canBeContacted: false,
		tracking: {
			userDeviceType: isMobile.value ? 'mobile' : 'desktop',
		},
	})

	// Revert to the actual locale after sending feedback improvements list
	locale.value = currentLocale

	props.onUpdateModalStatus(newFeedbackStatus)
	props.onUpdateModalPhase(FeedbackModalPhase.ThankYou)
}

onUnmounted(() => {
	props.onExpandTextArea(false)
})
</script>

<template>
	<div class="flex w-full flex-col gap-[14px]">
		<SpButton
			class="ml-auto"
			:icon-name="SpCommonIcon.Times"
			:size="isMobile ? SpButtonSize.DefaultIconOnly : SpButtonSize.SmallIconOnly"
			:type="SpButtonType.Ghost"
			@click="
				onModalClose({
					eventId: useFeedbackStore().activeFeedback!.id,
					feedbackScore: feedbackMap[props.modalStatus.chosenFeedback!],
					canBeContacted: false,
				})
			"
		/>
		<SpTooltip
			class="feedback-icon mx-auto h-[58px] w-[58px] justify-center rounded-full bg-secondary-gray-100"
			:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_GO_BACK_EMOJI_TOOLTIP'])"
			:hide-cursor="true"
			@click="onPreviousPhaseClick"
		>
			<div class="flex h-[58px] w-[58px]">
				<span class="m-auto text-[32px]">{{ getFeedbackIcon }}</span>
			</div>
		</SpTooltip>
		<div class="w-full text-center">
			<SpText
				:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.IMPROVEMENTS_LIST_TITLE'])"
				:size="SpTextSize.HeadingMd02"
			/>
		</div>
		<ul class="flex flex-col gap-0.5">
			<li
				:class="getImprovementCheckboxClass(improvementCheckbox)"
				v-for="improvementCheckbox in currentFeedbackModalImprovements"
			>
				<SpCheckbox
					v-model="improvementCheckbox.isSelected"
					@update:model-value="onImprovementCheckboxClick(improvementCheckbox)"
				/>
				<SpText class="self-center" :text="i18n.t(improvementCheckbox.label)" :size="SpTextSize.BodyMd02" />
			</li>
		</ul>
		<textarea
			class="w-full resize-none rounded-md border border-main-blue-200 bg-main-blue-50 px-4 py-2 text-secondary-gray-900 placeholder:text-sm placeholder:text-main-blue-500"
			v-model="textAreaContent"
			:rows="getTextAreaRows"
			:onfocus="expandTextArea"
			:placeholder="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_COMMENT_PLACEHOLDER'])"
		/>
		<SpButton :type="SpButtonType.Primary" @click="onFeedbackSend">
			<SpText
				class="w-full justify-center"
				:text="i18n.t(TranslationKeys['FEEDBACK.MODAL.GENERIC_SEND_BUTTON'])"
				:size="SpTextSize.BodyMd02"
			/>
		</SpButton>
	</div>
</template>

<style scoped>
.feedback-icon {
	background: linear-gradient(180deg, #8a9bf0 0%, #aef0f3 100%);
	box-shadow:
		0px 4px 14px 0px rgba(174, 240, 243, 0.85),
		0px -4px 12px 0px rgba(81, 84, 219, 0.4),
		0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
	cursor: pointer;
}
</style>
