import { useGtm } from '@gtm-support/vue-gtm'
import { defineStore } from 'pinia'
import userflow from 'userflow.js/src/userflow'
import { utilTracking } from '~/utils/utilTracking'
import { SalesModes } from '../constants/salesMode'
import { User } from '../submodules/sharedTypes/common/User'

import { utilNetwork } from '../utils/UtilNetwork'
import { UtilSales } from '../utils/UtilSales'
import { Feature } from './../submodules/sharedTypes/common/Feature'
import { decodeJwt } from '../utils/utilToken'
import { StorageKeys } from '@/constants/storageKeys'
import { SubscriptionsData } from '../submodules/sharedTypes/common/SubscriptionsData'

type UserState = {
	userData: User
	userflowInitialized: Boolean
	subscriptions: SubscriptionsData | undefined
}

export const useUserStore = defineStore('⚙️ user', {
	state: (): UserState => ({
		userData: {} as User,
		userflowInitialized: false,
		subscriptions: undefined,
	}),
	actions: {
		async setUser(user: User) {
			this.userData = user
			let userId = user.id.toString()
			let userEmail = user.email
			let userName = user.username

			// tracking
			if (this.isGhtDemoUser) {
				const decodedJWT = decodeJwt(localStorage.getItem(StorageKeys.SessionToken)!)
				userId = `${decodedJWT.ghtUserId}${userId}`
				userEmail = decodedJWT.ghtUsername as string
				userName = decodedJWT.ghtUsername as string
				utilTracking.identify(userId, false)
			} else {
				utilTracking.identify(userId, user.isAccountingSession)
			}

			// guided tour
			if (useRuntimeConfig().public.USERFLOW_TOKEN) {
				userflow.init(useRuntimeConfig().public.USERFLOW_TOKEN)
				const isAccountingForUserflow = user.isAccountingSession || (this.isDemoUser && !this.isGhtDemoUser)
				userflow
					.identify(userId, {
						name: userName,
						email: userEmail,
						signed_up_at: new Date().toISOString(),
						selected_lang: useLocale().currentLocale?.value,
						locale_code: useLocale().currentLocale?.value,
						is_accounting: isAccountingForUserflow.toString(),
						is_SPF_demo_account: this.isGhtDemoUser,
						device_type: isMobile() ? 'mobile' : 'desktop',
					})
					.then(() => {
						this.userflowInitialized = true
					})
			}

			if (this.isGhtDemoUser) {
				UtilSales.setSalesMode(SalesModes.Hotel)
			}

			if (useGtm()?.trackEvent != undefined) {
				useGtm()!.trackEvent({
					event: 'userData',
					userId: user.id,
				})
			}

			if (this.isDemoUser) {
				UtilSales.setSalesMode(SalesModes.Partner)
			}

			UtilSales.computeSalesMode()
		},
		setSubscriptions(subscriptions: SubscriptionsData | undefined) {
			this.subscriptions = subscriptions
		},
	},
	getters: {
		isAccountingUser(): boolean {
			return this.userData.isAccountingSession
		},
		isUserLoggedIn(): boolean {
			return !!this.userData?.id
		},
		getEnabledFeatures(): Feature[] {
			return this.userData?.featuresEnabled || []
		},
		isDemoUser(): boolean {
			return this.userData.role?.toLowerCase() === 'demo'
		},
		isGhtDemoUser(): boolean {
			const { origin } = decodeJwt(localStorage.getItem(StorageKeys.SessionToken)!)
			return origin === 'ght'
		},
	},
})
