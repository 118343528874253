import { GetSelfOnboardingProgressNetworkObject } from '~/submodules/sharedTypes/communication/onboarding/self/GetSelfOnboardingProgress/GetSelfOnboardingProgressNetworkObject'
import { StorageKeys } from '../constants/storageKeys'
import { TrackingMessages } from '../constants/trackingMessages'
import { ISocketHandler, socketListenerMap } from '../networkListeners/socketListenersMap'
import { useAccommodationsStore } from '../store/accommodations'
import { useCoreStore } from '../store/core'
import { useFeedbackStore } from '../store/feedback'
import { useUserPreferencesStore } from '../store/userPreferences'
import { WSMessageEvent } from '../submodules/sharedTypes/common/WebSocket'
import { GetUserDataNetworkObject } from '../submodules/sharedTypes/communication/me/GetUserDataNetworkObject'
import { GetUserDataResponse } from '../submodules/sharedTypes/communication/me/GetUserDataResponse'
import { SharedTrackingProperties } from '../submodules/sharedTypes/tracking/SharedTrackingProperties'
import { TrackingBillingStatus } from '../submodules/sharedTypes/tracking/TrackingBillingStatus'
import { useUserStore } from './../store/user'
import { useUserSettingsStore } from './../store/userSettings'
import { isMobileDevice } from './mobile'
import { utilBadPayment } from './utilBadPayment'
import { utilHotjar } from './utilHotjar'
import { utilNetwork } from './UtilNetwork'
import { utilObject } from './utilObject'
import { utilTracking } from './utilTracking'
import { utilTrialPeriod } from './utilTrialPeriod'
import { utilHubspot } from './utilHubspot'
import { SelfOnboardingStep } from '~/submodules/sharedTypes/common/SelfOnboarding'
import { useSmartpayingStore } from '~/store/smartpaying'
import { Core } from '../config/Core'

/*
 * This function is used to redirect the user to the correct page after login.
 * */
export const redirectUserAfterLogin = async (isLogin: boolean) => {
	const {
		hasOnlySmartpayingEnabled,
		hasOnlyAccommodationsInPreOnboardingStatus,
		isMarketIntelligenceEnabled,
		hasOnlyMarketIntelligenceEnabled,
		hasOnlySmartupsellingEnabled,
		isSmartpricingEnabled,
		isSmartpayingFreeEnabled,
		isSmartupsellingEnabled,
		hasOnlyAccommodationInOnboardingStatus,
		canStartAutoonboardingFromExistingAccommodation,
		hasAccommodationsInBadPayment,
		hasAllAccommodationsInLost,
	} = useCoreStore()
	const { isAccountingUser } = useUserStore()
	const { isMobile } = useMobileViewport()
	const isAutoOnboardingRoute = useRoute().path.startsWith('/auto-onboarding')
	const isOnboardingRoute = useRoute().path.startsWith('/onboarding')
	const isCompetitorsRoute = useRoute().path.startsWith('/competitors')
	const { currentStep } = storeToRefs(useAutoOnboardingStore())
	const { accommodations } = storeToRefs(useAccommodationsStore())
	const { subscriptions } = useUserStore()

	let hasAlreadyBeenRedirected = false
	const route = useRoute().path
	const isSmartpayingPage = Core.smartpayingOnlyRoutes.some((spRoute) => route.startsWith(spRoute))

	// Check if the user has a bad payment status
	if ((subscriptions && utilBadPayment.isBadPayment(subscriptions?.paymentStatus)) || hasAccommodationsInBadPayment) {
		const route = useRoute().path
		const isBillingRoute = route.startsWith('/settings/billing')

		if (isBillingRoute) return

		if (
			(utilBadPayment.isBadSmartpricingPayment(subscriptions?.paymentStatus) &&
				Core.smartpricingOnlyRoutes.includes(route)) ||
			(utilBadPayment.isBadSmartpayingPayment(subscriptions?.paymentStatus) &&
				Core.smartpayingOnlyRoutes.includes(route))
		) {
			hasAlreadyBeenRedirected = true
			await navigateTo('/settings/billing')
		}
	}

	if (hasAllAccommodationsInLost) {
		if (hasOnlySmartupsellingEnabled) {
			location.assign(window.location.origin + '/marketplace')
		} else if (hasOnlySmartpayingEnabled) {
			window.location.assign(useRuntimeConfig().public.SMARTPAYING_URL_OLD)
		} else if (!isSmartpricingEnabled && (isSmartpayingFreeEnabled || isSmartupsellingEnabled)) {
			isSmartupsellingEnabled
				? location.assign(window.location.origin + '/marketplace')
				: location.assign(window.location.origin + '/payments')
		} else {
			localStorage.setItem(StorageKeys.UserForcedLogout, 'true')

			utilLogout.logout()
			return
		}
	}

	// If the user has no accommodation linked:
	// - is accounting user from desktop: then redirect to /auto-onboarding
	// - is not accounting user or is from mobile: then logout
	if (!accommodations.value.length && isAccountingUser && !isMobile.value) {
		await navigateTo('/auto-onboarding')
		return
	} else if (!accommodations.value.length && !isAccountingUser) {
		await navigateTo('/onboarding')
		return
	} /* else if (!accommodations.value.length && (!isAccountingUser || isMobile.value)) {
		utilLogout.logout()
	} */
	// If the user has one or more accommodations linked in the onboarding statuses.
	// First we need to require the self onboarding progress:
	// - if the user has no progress, then redirect to /calendar
	// - if the user has progress, then redirect to related step
	else if (
		!isSmartpayingPage &&
		(hasOnlyAccommodationInOnboardingStatus || canStartAutoonboardingFromExistingAccommodation)
	) {
		utilNetwork.simpleRequest(new GetSelfOnboardingProgressNetworkObject({}))
		if (currentStep.value === null) {
			await awaitRef(currentStep)
		}

		if (currentStep.value && isAccountingUser) {
			isAutoOnboardingRoute ? null : await navigateTo('/auto-onboarding')
			return
		} else if (canStartAutoonboardingFromExistingAccommodation && isAccountingUser) {
			await navigateTo('/auto-onboarding')
			return
		} else {
			if (hasOnlyAccommodationsInPreOnboardingStatus) {
				isOnboardingRoute ? null : await navigateTo('/onboarding')
				return
			} else {
				await navigateTo('/calendar')
				return
			}
		}
	} else if (!hasAlreadyBeenRedirected) {
		// Then, handle Smartpaying lifecycle
		if (hasOnlySmartupsellingEnabled) {
			location.assign(window.location.origin + '/marketplace')
		} else if (hasOnlySmartpayingEnabled) {
			window.location.assign(useRuntimeConfig().public.SMARTPAYING_URL_OLD)
		} else if (!isSmartpricingEnabled && (isSmartpayingFreeEnabled || isSmartupsellingEnabled)) {
			isSmartupsellingEnabled
				? location.assign(window.location.origin + '/marketplace')
				: location.assign(window.location.origin + '/payments')
		} else if (hasOnlyMarketIntelligenceEnabled) {
			await navigateTo('/competitors')
		}
		// Handle Onboarding users
		else if (hasOnlyAccommodationsInPreOnboardingStatus) {
			isOnboardingRoute ? null : await navigateTo('/onboarding')
			return
		}
		// Prevent access to restricted routes, if not enabled
		else if (
			(isCompetitorsRoute && !isMarketIntelligenceEnabled) ||
			(isAutoOnboardingRoute && !hasOnlyAccommodationInOnboardingStatus) ||
			(isOnboardingRoute && !hasOnlyAccommodationsInPreOnboardingStatus)
		) {
			await navigateTo('/calendar')
		}

		if (isLogin) {
			const redirect = useRoute().query.redirect?.toString()
			!!redirect ? navigateTo(redirect) : navigateTo('/calendar')
		}
	}
}

export async function loadLoggedSectionPrereqs() {
	const userStore = useUserStore()
	if (userStore.userData?.id == undefined) {
		utilNetwork.registerServerInteractions(utilObject.objectToMap<WSMessageEvent, ISocketHandler>(socketListenerMap))

		await initiateUserAndTracking()
	}

	useFeedbackStore().init()

	const accommodationStore = useAccommodationsStore()
	if (accommodationStore.accommodations.length == 0) {
		await accommodationStore.getAccommodations({})
	}

	useNuxtApp().$sentrySetUser({
		id: userStore.userData?.id,
		username: userStore.userData?.username,
		email: userStore.userData?.email,
		room_types_count: accommodationStore.roomTypes?.length,
		units_count: accommodationStore.roomTypes.map((rt) => rt.numUnits).reduce((a, e) => a + e, 0),
		accomodations_count: accommodationStore.accommodations.length,
	})

	if (useCoreStore().isSmartpayingEnabled || useCoreStore().isSmartpayingFreeEnabled) {
		await useSmartpayingStore().load()
	}

	if (utilRouter.currentPath() !== '/onboarding' && useCoreStore().hasOnlyAccommodationsInPreOnboardingStatus) {
		utilTracking.setSharedProperty(SharedTrackingProperties.BillingStatus, TrackingBillingStatus.Onboarding)
	}

	useUserSettingsStore().requestUserSettings()

	if (sessionStorage.getItem(StorageKeys.JustLogged) == 'true') {
		utilTracking.track(TrackingMessages.SIGN_IN, {
			login_method: sessionStorage.getItem(StorageKeys.JustLogged) || 'Email',
		})
		sessionStorage.removeItem(StorageKeys.JustLogged)
	}
}

const initiateUserAndTracking = async () =>
	utilNetwork.simpleRequest(new GetUserDataNetworkObject({}), (res: GetUserDataResponse) => {
		useUserStore().setUser(res.user)
		useUserStore().setSubscriptions(res.subscriptionsData)
		useUserPreferencesStore().setUserPreferences(res.preferences)

		utilTracking.setSharedProperty(SharedTrackingProperties.AccommodationsCities, res.trackingInfo.accommodationsCities)
		utilTracking.setSharedProperty(
			SharedTrackingProperties.AccommodationsCountries,
			res.trackingInfo.accommodationsCountries
		)
		utilTracking.setSharedProperty(
			SharedTrackingProperties.AccommodationsRegions,
			res.trackingInfo.accommodationsRegions
		)
		utilTracking.setSharedProperty(SharedTrackingProperties.EnvironmentType, res.trackingInfo.environmentType)
		utilTracking.setSharedProperty(
			SharedTrackingProperties.BillingStatus,
			res.subscriptionsData?.trialPeriodExpirationDate != undefined
				? TrackingBillingStatus.Trial
				: TrackingBillingStatus.Paying
		)
		utilTracking.setSharedProperty(SharedTrackingProperties.IsMobileVersion, isMobileDevice())

		utilTrialPeriod.handlePeriod(res.subscriptionsData?.trialPeriodExpirationDate)
		utilBadPayment.handlePaymentStatus(res.subscriptionsData?.paymentStatus, res.subscriptionsData?.accountLockDate)

		let userId = res.user.id
		let email = res.user.email
		if (useUserStore().isGhtDemoUser) {
			const decodedJWT = decodeJwt(localStorage.getItem(StorageKeys.SessionToken)!)
			userId = decodedJWT.ghtUserId as number
			email = decodedJWT.ghtUsername as string
		}
		utilHotjar.identify(userId, email, res.subscriptionsData?.paymentStatus)

		utilHubspot.identify(email)
	})
