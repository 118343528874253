import { GetRulesByAccommodationIdResponse } from '~/submodules/sharedTypes/communication/minimumStay/GetRulesByAccommodationIdResponse'
import { GetRulesByAccommodationIdNetworkObject } from '../submodules/sharedTypes/communication/minimumStay/GetRulesByAccommodationIdNetworkObject'
import { LoadingIds } from '../constants/loadingIds'
import _, { cloneDeep } from 'lodash'
import { TrackingMessages } from '../constants/trackingMessages'
import { CardTypes } from '../constants/cardTypes'
import { UpdateRulesNetworkObject } from '~/submodules/sharedTypes/communication/minimumStay/UpdateRulesNetworkObject'
import { defineStore, storeToRefs } from 'pinia'
import { useAccommodationsStore } from './accommodations'
import { DayOfTheWeekMap, MinimumStayRule, OrphanNightsFixedDate } from '~/submodules/sharedTypes/common/MinimumStay'
import { GetOrphanNightsFixedDatesNetworkObject } from '~/submodules/sharedTypes/communication/minimumStay/GetOrphanNightsFixedDatesNetworkObject'
import { addDays, differenceInCalendarDays } from 'date-fns'
import { calendarConfig } from '../config/CalendarConfig'
import { GetOrphanNightsFixedDatesResponse } from '~/submodules/sharedTypes/communication/minimumStay/GetOrphanNightsFixedDatesResponse'
import { useLoadingStore } from './loading'
import { GapFillingUnavailableNetworkObject } from '../submodules/sharedTypes/communication/generic/GapFillingUnavailableNetworkObject'
import { MinimumStayItem } from '../types/MinStay'
import { reset } from 'mixpanel-browser'
import { debug } from 'console'

export const useMinimumStayStore = defineStore('minimun stay', () => {
	const minimumStayRules = ref<MinimumStayItem[]>()
	const originalMinimumStayRules = ref<MinimumStayItem[]>()
	const accommodationsStore = useAccommodationsStore()
	const { selectedAccommodation } = storeToRefs(useSettingsStore())
	const fixedDatesStartDate: Ref<Date> = ref(new Date())
	const fixedDates = ref(new Map<number, OrphanNightsFixedDate[]>())

	const fetchMinStay = async (accommodationId: string) => {
		utilNetwork.simpleRequest(
			new GetRulesByAccommodationIdNetworkObject({ accommodationId }),
			undefined,
			LoadingIds.GET_MINIMUM_STAY_RULES
		)

		// reset rules on accommodation change
		minimumStayRules.value = []
	}
	const setMinStayFromResponse = (response: GetRulesByAccommodationIdResponse) => {
		minimumStayRules.value = response.rules || []

		sortPeriods()

		originalMinimumStayRules.value = cloneDeep(minimumStayRules.value)
	}

	const { selectedRoomType, getSelectedTab } = storeToRefs(useSettingsStore())
	const defaultRule = computed(() => {
		const isAccommodationMode = getSelectedTab.value === 'accommodation-minimum-stay'

		if (selectedRoomType.value && !isAccommodationMode) {
			return (
				minimumStayRules.value
					?.filter((rule) => !!rule.roomTypeId)
					.find((rule) => rule.kind === 'default' && rule.roomTypeId === selectedRoomType.value?.id) ?? {
					...minimumStayRules.value?.find((rule) => rule.kind === 'default' && !rule.roomTypeId),
					isInherited: true,
				}
			)
		}

		return minimumStayRules.value?.find((rule) => rule.kind === 'default' && !rule.roomTypeId)
	})
	const periodRules = computed(() => {
		const isAccommodationMode = getSelectedTab.value === 'accommodation-minimum-stay'

		if (selectedRoomType.value && !isAccommodationMode) {
			return minimumStayRules.value
				?.filter((rule) => !!rule.roomTypeId)
				.filter((rule) => rule.kind === 'static' && rule.roomTypeId === selectedRoomType.value?.id)
		}

		return minimumStayRules.value?.filter((rule) => rule.dateRange && !rule.roomTypeId)
	})
	const hasCustomDefault = computed(() => (roomTypeId?: number) => {
		if (!roomTypeId) {
			return !!minimumStayRules.value?.find((rule) => rule.kind === 'default' && !rule.roomTypeId)?.id
		}

		return !!minimumStayRules.value?.find(
			(rule) => rule.kind === 'default' && rule.roomTypeId === roomTypeId && !rule.isTemporaryDefault
		)?.id
	})
	const hasCustomPeriods = computed(() => (roomTypeId?: number) => {
		if (!roomTypeId) {
			return !!minimumStayRules.value?.find((rule) => rule.kind === 'static' && !rule.roomTypeId)?.id
		}

		return !!minimumStayRules.value?.find((rule) => rule.kind === 'static' && rule.roomTypeId === roomTypeId)?.id
	})

	const showOrphanNightsCalendarLegend = computed(
		() =>
			accommodationsStore.accommodations.find((accommodation) => accommodation.isGapFillingAvailable === true) !==
			undefined
	)
	const showOrphanNightsFixedDate = computed(() => {
		return (roomTypeId: number | undefined, date: Date) => {
			if (!roomTypeId) {
				return false
			}

			const fixedDatesToConsider = fixedDates.value.get(roomTypeId) || []
			const fixedDateIndex = fixedDatesToConsider.findIndex((fixedDate) => fixedDate.date.getTime() === date.getTime())

			if (fixedDateIndex <= -1) {
				return false
			}

			return fixedDatesToConsider[fixedDateIndex].orphanNightsCount > 0
		}
	})
	const getFixedDatesByRoomTypeId = computed(() => {
		return (roomTypeId: number) => fixedDates.value.get(roomTypeId) || []
	})

	const addPeriod = (period: MinimumStayItem, generateId: boolean = true) => {
		if (minimumStayRules.value) {
			if (!period.dateRange) {
				minimumStayRules.value.push(period)
				return
			}

			const defaultPeriods = minimumStayRules.value.filter((rule) => rule.kind === 'default')
			minimumStayRules.value = [
				...defaultPeriods,
				...minimumStayRules.value.filter((rule) => rule.kind !== 'default' && rule.roomTypeId !== period.roomTypeId),
				// @ts-expect-error - dateRange is actually always present in period different from default
				...utilDate.addPeriodAndSplitOverlap<MinimumStayItem>(
					minimumStayRules.value.filter(
						(rule) => rule.kind !== 'default' && rule.dateRange && rule.roomTypeId === period.roomTypeId
					),
					{
						...cloneDeep(period),
						id: generateId ? crypto.randomUUID() : period.id,
					}
				),
			]

			sortPeriods()
		}
	}

	const editPeriod = (period: MinimumStayItem) => {
		if (period.kind === 'default') {
			const periodIndex =
				minimumStayRules.value?.findIndex((rule) => rule.kind === 'default' && rule.roomTypeId === period.roomTypeId) ??
				-1
			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value[periodIndex] = period
			}
		} else {
			const periodIndex = minimumStayRules.value?.findIndex((rule) => rule.id === period.id) ?? -1
			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value.splice(periodIndex, 1)
				addPeriod(period, false)
			}
		}
		sortPeriods()
	}

	const removePeriod = (id: string) => {
		if (!id || !minimumStayRules.value) {
			return
		}

		const index = minimumStayRules.value.findIndex((rule) => rule.id === id)
		minimumStayRules.value.splice(index, 1)
	}

	const sortPeriods = () => {
		if (!minimumStayRules.value) {
			return
		}

		const defaultRules = minimumStayRules.value.filter((rule) => rule.kind === 'default')
		const sortedRules = utilDate.sortDateRangeArray<MinimumStayItem>(
			minimumStayRules.value.filter((rule) => rule.kind !== 'default')!,
			'dateRange'
		)

		minimumStayRules.value = [...defaultRules, ...sortedRules]
	}

	const resetDefaultPeriod = (roomTypeId?: number) => {
		const emptyDefaultRule: MinimumStayItem = {
			accommodationId: useSettingsStore().selectedAccommodation!.id,
			kind: 'default',
			minStay: {
				dayOfTheWeek: {},
			},
			rollingRules: [],
			gapFilling: {
				dayOfTheWeek: {},
				active: false,
			},
		}

		if (!roomTypeId) {
			const periodIndex = minimumStayRules.value?.findIndex((rule) => rule.kind === 'default' && !rule.roomTypeId) ?? -1

			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value[periodIndex] = emptyDefaultRule
			}
		} else {
			const periodIndex =
				minimumStayRules.value?.findIndex((rule) => rule.kind === 'default' && rule.roomTypeId === roomTypeId) ?? -1
			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value.splice(periodIndex, 1)
			}
		}
	}

	const requestMinStaySupport = () => {
		utilNetwork.simpleRequest(
			new GapFillingUnavailableNetworkObject({
				// @ts-expect-error - cm is always present in accommodation, for some reason the used type is the GHT one
				channelManager: useSettingsStore().selectedAccommodation.cm,
			}),
			() => {}
		)
	}

	// Save handlers
	const checkForChanges = computed(() => !_.isEqual(minimumStayRules.value, originalMinimumStayRules.value))
	const resetChanges = () => {
		minimumStayRules.value = cloneDeep(originalMinimumStayRules.value)
	}
	const save = async () => {
		const addedRules: MinimumStayItem[] = []
		const editedRules: MinimumStayItem[] = []
		const removedRules: MinimumStayItem[] = []

		const originalRuleIds = originalMinimumStayRules.value?.map((rule) => rule.id).filter((id) => id)
		minimumStayRules.value?.forEach((rule) => {
			if (originalRuleIds?.includes(rule.id)) {
				!rule.isInherited && editedRules.push(rule)
			} else {
				addedRules.push(rule)
			}
		})
		originalMinimumStayRules.value?.forEach((rule) => {
			if (
				!minimumStayRules.value?.find((r) => r.id === rule.id) ||
				(rule.dateRange && utilDate.isBeforeToday(rule.dateRange?.to)) ||
				(rule.isInherited && rule.id)
			) {
				removedRules.push(rule)
			}
		})

		const accommodationId = useSettingsStore().selectedAccommodation!.id

		// Track informations
		addedRules.forEach((rule) => {
			if (!_.isEqual(defaultRule.value?.minStay, rule.minStay)) {
				utilTracking.track(TrackingMessages.MIN_STAY_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					min_stay_value: rule.minStay.default,
					min_stay_dow: Array.from({ length: 7 }, (_, i) => rule.minStay.dayOfTheWeek[i as keyof DayOfTheWeekMap]),
				})
			}

			if (!_.isEqual(rule.rollingRules, defaultRule.value?.rollingRules)) {
				utilTracking.track(TrackingMessages.DYNAMIC_MIN_STAY_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					rules_count: rule.rollingRules?.length,
					has_set_dow: rule.rollingRules?.some((r) => Object.values(r.minStayDayOfTheWeek ?? {}).length) ?? false,
				})
			}

			if (!_.isEqual(rule.gapFilling, defaultRule.value?.gapFilling)) {
				utilTracking.track(TrackingMessages.GAP_FILLING_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					is_activated: rule.gapFilling.active,
					gap_filling_value: rule.gapFilling.active ? rule.gapFilling.default : undefined,
					gap_filling_dow: Array.from(
						{ length: 7 },
						(_, i) => rule.gapFilling.dayOfTheWeek[i as keyof DayOfTheWeekMap]
					),
				})
			}
		})

		editedRules?.forEach((rule) => {
			const originalRule = originalMinimumStayRules.value?.find((r) => r.id === rule.id)
			if (
				!_.isEqual(originalRule?.minStay, rule.minStay) ||
				(!_.isEqual(originalRule?.dateRange, rule.dateRange) && !_.isEqual(rule.minStay, defaultRule.value?.minStay))
			) {
				utilTracking.track(TrackingMessages.MIN_STAY_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					min_stay_value: rule.minStay.default,
					min_stay_dow: Array.from({ length: 7 }, (_, i) => rule.minStay.dayOfTheWeek[i as keyof DayOfTheWeekMap]),
				})
			}

			if (
				!_.isEqual(originalRule?.rollingRules, rule.rollingRules) ||
				(!_.isEqual(originalRule?.dateRange, rule.dateRange) &&
					!_.isEqual(rule.rollingRules, defaultRule.value?.rollingRules))
			) {
				utilTracking.track(TrackingMessages.DYNAMIC_MIN_STAY_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					rules_count: rule.rollingRules?.length,
					has_set_dow: rule.rollingRules?.some((r) => Object.values(r.minStayDayOfTheWeek ?? {}).length) ?? false,
				})
			}

			if (
				!_.isEqual(originalRule?.gapFilling, rule.gapFilling) ||
				(!_.isEqual(originalRule?.dateRange, rule.dateRange) &&
					!_.isEqual(rule.gapFilling, defaultRule.value?.gapFilling))
			) {
				utilTracking.track(TrackingMessages.GAP_FILLING_SETTING_CHANGED, {
					ps_id: accommodationId,
					accommodation_name: selectedAccommodation.value?.name,
					room_type_name: useAccommodationsStore().getRoomTypeById(rule.roomTypeId)?.name,
					period_type: rule.dateRange ? 'Custom' : 'Default',
					period_selected_days: rule.dateRange
						? differenceInCalendarDays(rule.dateRange.to, rule.dateRange.from) + 1
						: undefined,
					is_activated: rule.gapFilling.active,
					gap_filling_value: rule.gapFilling.active ? rule.gapFilling.default : undefined,
					gap_filling_dow: Array.from(
						{ length: 7 },
						(_, i) => rule.gapFilling.dayOfTheWeek[i as keyof DayOfTheWeekMap]
					),
				})
			}
		})

		await utilNetwork.simpleRequest(
			new UpdateRulesNetworkObject({
				accommodationId,
				ruleToAdd: addedRules.map((rule) => {
					if (!rule.dateRange) {
						return rule
					}

					return {
						...rule,
						dateRange: {
							from: generateDateAsIfClientIsInUTCTimezone(rule.dateRange.from),
							to: generateDateAsIfClientIsInUTCTimezone(rule.dateRange.to),
						},
					}
				}) as MinimumStayRule[],
				rulesToEdit: editedRules.map((rule) => {
					if (!rule.dateRange) {
						return rule
					}

					return {
						...rule,
						dateRange: {
							from: generateDateAsIfClientIsInUTCTimezone(rule.dateRange.from),
							to: generateDateAsIfClientIsInUTCTimezone(rule.dateRange.to),
						},
					}
				}) as MinimumStayRule[],
				rulesToDelete: removedRules?.map((rule) => rule!.id!) ?? [],
			}),
			undefined,
			LoadingIds.GET_MINIMUM_STAY_RULES
		)

		useNotificationsStore().addNotification({
			canClose: true,
			cardType: CardTypes.SUCCESS,
			title: TranslationKeys['settings.minimum_stay_save.TITLE'],
			message: TranslationKeys['settings.minimum_stay_save.DESCRIPTION'],
		})
	}

	function setFixedDatesStartDate(newDate: Date) {
		fixedDatesStartDate.value = newDate
	}
	function setFixedDates(res: GetOrphanNightsFixedDatesResponse) {
		res.accommodations.forEach((accommodation) => {
			accommodation.fixedDates.forEach((fixedDate) => {
				if (!fixedDates.value.has(fixedDate.roomTypeId)) {
					fixedDates.value.set(fixedDate.roomTypeId, [])
				}
				fixedDates.value.get(fixedDate.roomTypeId)!.push(fixedDate)
			})
		})
	}

	async function requestFixedDates() {
		$reset()
		const loadingStore = useLoadingStore()
		await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

		const accommodations = accommodationsStore.accommodationsToShow.length
			? accommodationsStore.accommodationsToShow
			: accommodationsStore.accommodations.map((element) => element.id)

		const request = new GetOrphanNightsFixedDatesNetworkObject({
			accommodationIds: accommodations,
			dateRange: {
				from: fixedDatesStartDate.value,
				to: addDays(fixedDatesStartDate.value, calendarConfig.maxDays - 1),
			},
		})
		utilNetwork.simpleRequest(request, undefined, LoadingIds.GET_FIXED_DATES)
	}

	// Utils
	function $reset() {
		fixedDates.value.clear()
	}

	return {
		minimumStayRules,
		fetchMinStay,
		setMinStayFromResponse,
		defaultRule,
		periodRules,
		hasCustomDefault,
		hasCustomPeriods,

		addPeriod,
		editPeriod,
		removePeriod,
		resetDefaultPeriod,
		requestMinStaySupport,

		checkForChanges,
		resetChanges,
		save,

		selectedAccommodation,

		// Calendar
		showOrphanNightsCalendarLegend,
		showOrphanNightsFixedDate,
		getFixedDatesByRoomTypeId,
		setFixedDatesStartDate,
		setFixedDates,

		requestFixedDates,

		// Utils
		$reset,
	}
})
