import { StorageKeys } from '~/constants/storageKeys'

class FeedbackService {
	static instance: FeedbackService | null = null
	private readonly baseUrl: string = ''
	private readonly projectId: string = ''

	constructor() {
		if (FeedbackService.instance) {
			return FeedbackService.instance
		}

		this.baseUrl = useRuntimeConfig().public.FEEDBACK_URL || ''
		this.projectId = useRuntimeConfig().public.FEEDBACK_PROJECT || ''

		if (!this.baseUrl || !this.projectId) {
			console.error('Feedback service not initialized, missing configuration', {
				baseUrl: this.baseUrl,
				projectId: this.projectId,
			})
		}

		FeedbackService.instance = this
	}

	static getInstance() {
		if (!FeedbackService.instance) {
			FeedbackService.instance = new FeedbackService()
		}
		return FeedbackService.instance
	}

	async makeRequest(endpoint: string, body = {}) {
		const url = `${this.baseUrl}${endpoint}?project_key=${this.projectId}`
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem(StorageKeys.SessionToken) || ''}`,
			},
			body: JSON.stringify({
				...body,
			}),
			method: 'POST',
		})

		if (!response.ok) {
			throw new Error(`API request failed: ${response.statusText}`)
		}

		return response.json()
	}

	async trackScore(featureName: string, featureScore: number) {
		return this.makeRequest(`/scores`, {
			featureName: featureName,
			score: featureScore,
		})
	}
}

// Export the getInstance method instead of a new instance
export default FeedbackService.getInstance()
